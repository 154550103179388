export const words = [
  "TRAIN",
  "SALES",
  "HEAVEN",
  "SKY",
  "FEAR",
  "KID",
  "HAPPY",
  "TEASER",
  "TEACHER",
  "PREACHER",
  "DELIVER",
  "SEND",
  "PAY",
  "HELP",
  "TRAILER",
  "HOUSE",
  "FAN",
  "BED",
  "GLASS",
  "WINDOW",
  "PEOPLE",
  "WARNING",
  "DEAR",
  "MAN",
  "BRAIN",
  "BALLOON",
  "ROOM",
  "ENGINE",
  "TASK",
  "MEAL",
  "EVIL",
  "DEVIL",
  "ARM",
  "CHEESE",
  "FOOT",
  "SHOE",
  "WINE",
  "MONKEY",
  "DONKEY",
  "HONEY",
  "ROAD",
  "TOAD",
  "LOAD",
  "LOYAL",
  "DOG",
  "BIRD",
  "SIGN",
  "SING",
  "THING",
  "KING",
  "MIRROR",
  "ROSE",
  "BOY",
  "TOY",
  "SOY",
  "CAT",
  "RESPECT",
  "RIVER",
  "SUGAR",
  "BEAR",
  "BEARD",
  "BEER",
  "BUNNY",
  "FUNNY",
  "HURRY",
  "DINNER",
  "INNER",
  "LIVER",
  "SHEET",
  "HEAT",
  "HAT",
  "CAT",
  "PATH",
  "PIT",
  "FIT",
  "BOX",
  "FISH",
  "TEETH",
  "WISH",
  "SCREAM",
  "GHOST",
  "TOAST",
  "DUST",
  "RUST",
  "JELLY",
  "BELL",
  "ROW",
  "OWL",
  "LOW",
  "DOWN",
  "COAT",
  "COUCH",
  "MOOD",
  "DOODLE",
];
